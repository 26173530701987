import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }

  .how-make {
    margin-left: 16px;

    svg {
      color: #00c853;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .user {
    width: 20vw;
  }

  .appointment {
    width: 50vw;
  }

  .period {
    width: 20vw;
  }

  .conclusion {
    width: 10vw;
  }

  @media (max-width: 1400px) {
    .appointment {
      width: 45vw;
    }

    .conclusion {
      width: 15vw;
    }
  }

  @media (max-width: 1200px) {
    .appointment {
      width: 35vw;
    }

    .period {
      width: 25vw;
    }

    .conclusion {
      width: 20vw;
    }
  }

  .arrowUser {
    border: 0;
    background: transparent;
    margin: 25px 8px 0 8px;
    transition: opacity 0.2s;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 15px;
      width: 15px;
    }
  }

  > button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    pointer-events: none;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;

    input {
      height: 10px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      height: 30px;
      background: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      text-align: center;
    }

    span {
      margin: 0 10px;
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
      width: 100%;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .private,
    .priority,
    .read {
      width: 4vw;
      text-align: center;
    }

    .start_date,
    .deadline_date {
      width: 10vw;
    }

    .start_hour {
      width: 8vw;
    }

    .description {
      width: 60vw;
      max-width: 60vw;
    }

    .warn {
      font-weight: bold;
      color: #f4a500;
    }

    .late {
      font-weight: bold;
      color: #e53935;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AppointmentInfo = styled.div`
  section {
    div + div {
      margin-left: 16px;
    }
  }

  .start_date,
  .deadline_date,
  .priority,
  .supplier,
  .request_conclusion,
  .request_extension {
    width: 15%;
  }

  .sender {
    width: 20%;
  }

  .description {
    flex: 1;
  }

  .start_hour,
  .deadline_hour,
  .price,
  .done,
  .type,
  .private {
    width: 10%;
  }

  .recipient {
    width: 40%;
  }

  .checkbox {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }

  .file {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    > aside {
      display: flex;
      flex-direction: column;

      button {
        background: none;
        color: ${({ theme }) => theme.colors.blue};
        padding: 2px;
        transition: color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    > div {
      flex: 1;

      margin-left: 8px;
    }
  }

  @media (max-width: 1024px) {
    .sender {
      width: 25%;
    }

    .request_conclusion,
    .request_extension {
      width: 18%;
    }
  }
`;

export const Feedback = styled(Form)`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  section {
    align-items: flex-end;
  }
`;

export const SendFeedback = styled.div`
  margin-left: 16px;

  display: flex;
  align-items: center;

  .feedback_message {
    width: 40vw;

    textarea {
      width: 40vw;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 7px;
    border: 1px solid ${({ theme }) => theme.colors.success};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;

export const UploadFile = styled.div`
  display: flex;
  align-items: center;
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 30vw;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    height: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    background: transparent;
  }
`;

export const File = styled.div`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: 16px;
    opacity: 1;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }
  }
`;

export const FeedbackItem = styled.section`
  margin-top: 16px;
  position: relative;

  display: flex;

  div {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
      opacity: 0.7;
    }

    input {
      height: 30px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      background: transparent;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    button {
      background: none;
      margin-right: 8px;
      padding: 2px;
      transition: color 0.2s;
      color: ${({ theme }) => theme.colors.error};

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: ${({ theme }) => theme.hover.error};
      }
    }
  }

  div + div {
    margin-left: 16px;
  }

  .delete {
    height: 40px;
    padding-top: 20px;

    display: flex;
    justify-content: center;
  }

  .date {
    margin-left: 0;
    width: 15%;
  }

  .user {
    width: 15%;
  }

  .file {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    > aside {
      display: flex;
      flex-direction: column;

      button {
        background: none;
        color: ${({ theme }) => theme.colors.blue};
        padding: 2px;
        transition: color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    > div {
      flex: 1;

      margin-left: 8px;
    }
  }

  .content {
    width: 40%;
  }
`;

export const TextAreaAsPlainText = styled.pre`
  color: #545454;
  margin-top: 8px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 16px;
`;

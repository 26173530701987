import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  .how-make {
    background: ${({ theme }) => theme.colors.white};
    color: #00c853;

    svg {
      margin-left: 10px;
      margin-right: 5px;
      color: #00c853;
    }
  }

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    > div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }

    .situation {
      flex-direction: row;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  h1 {
    margin-top: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue};
  }

  table {
    position: relative;
    width: 100%;

    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      text-align: center;
      top: 0;
      padding: 5px;

      &.fixed {
        z-index: 2;
        width: 30px;
        min-width: 30px;
        padding-left: 8px;
      }

      &:nth-child(1) {
        left: 0;
      }

      &:nth-child(2) {
        left: 30px;
      }

      &:nth-child(3) {
        left: 60px;
      }

      &:nth-child(4) {
        left: 90px;
      }

      &:nth-child(5) {
        left: 120px;
      }

      &:nth-child(6) {
        left: 150px;
        width: 140px;
        min-width: 140px;
        text-align: left;
      }

      &:nth-child(7) {
        left: 290px;
        width: 140px;
        min-width: 140px;
        text-align: left;
      }
    }

    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: #fafafa;

          th {
            background: #fafafa;
          }
        }

        &:nth-child(2n + 2) {
          background: #f4f4f4;

          th {
            background: #f4f4f4;
          }
        }
      }

      th {
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 5px;
        color: ${({ theme }) => theme.colors.grey};

        &.fixed {
          position: sticky;
          z-index: 1;
          max-width: 30px;
          padding-left: 8px;
          width: 30px;
        }

        &:nth-child(1) {
          left: 0;
        }

        &:nth-child(2) {
          left: 30px;
        }

        &:nth-child(3) {
          left: 60px;
        }

        &:nth-child(4) {
          left: 90px;
        }

        &:nth-child(5) {
          left: 120px;
        }

        &:nth-child(6) {
          left: 150px;
          width: 140px;
          min-width: 140px;
          text-align: left;
          max-width: 0;
          cursor: pointer;
        }

        &:nth-child(7) {
          left: 290px;
          width: 140px;
          min-width: 140px;
          text-align: left;
          max-width: 0;
          cursor: pointer;
        }
      }

      .icon-button {
        cursor: pointer;

        transition: opacity 0.3s;
        &:hover {
          opacity: 0.5;
        }
      }

      td {
        cursor: pointer;
        padding: 5px;
      }
    }
  }
`;

export const SituationButton = styled.div`
  border: 2px solid ${props => props.color || '#01579b'};
  border-radius: 50%;
  width: 15px;
  height: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;

  > div + div {
    margin-left: 16px;
  }

  .user,
  .conclusion,
  .view {
    width: 20%;
  }

  .all-users {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 8px;
    justify-content: center;
    align-items: center;

    input {
      margin: 0;
    }
  }

  > button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;

  section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  form {
    color: ${({ theme }) => theme.colors.primary};
  }

  .docs + .docs,
  .phases + .phases {
    margin-top: 40px;
  }

  .sector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .description {
    margin-top: 20px;
  }

  .title {
    width: 50%;
  }

  .document {
    width: 22%;
    min-width: 265px;
  }

  .date {
    width: 10%;
    min-width: 135px;
  }

  .file {
    width: 20%;
  }

  .obs {
    width: 100%;
    margin-left: 16px;
  }

  .answer {
    width: 40%;
    margin-left: 16px;
  }

  .type,
  .user {
    width: 12%;
  }

  .deadline {
    width: 90px;
  }

  .cpf {
    width: 120px;
  }

  .employee-date {
    width: 225px;
  }

  .name {
    width: 30%;
  }

  .checkbox {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    min-width: 5%;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }

  .viewer-user,
  .viewer-email {
    width: 40%;
  }

  .new-viewer {
    width: calc(50% - 66px);
    margin-left: 16px;
  }

  .edit,
  .delete {
    display: flex;
    align-items: flex-end;
    color: ${({ theme }) => theme.colors.primary};

    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  .delete {
    color: #e53935;
  }

  .create {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0px;

    h4 {
      padding-top: 16px;
    }

    button {
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .view-feedbacks {
    width: 20px;
    opacity: 1;
    transition: opacity 0.3s;
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 5px;

    button {
      display: flex;
      align-items: flex-end;

      border: 0;
      background: transparent;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .file {
    display: flex;
    flex-direction: row;
    align-items: center;

    > aside {
      display: flex;
      flex-direction: column;

      button {
        background: none;
        color: ${({ theme }) => theme.colors.blue};
        padding: 2px;
        transition: color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;

      margin-left: 8px;
    }
  }

  .form {
    width: 100%;
    margin-left: 16px;
  }

  .form-answer {
    margin-left: 16px;
    width: 40%;
  }

  .edit-button {
    width: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  div + .edit-button {
    margin-left: 16px;
  }
`;

export const Subtitles = styled.legend`
  display: flex;
  justify-content: flex-end;

  padding: 3px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 10px;
  }

  aside {
    display: flex;

    div {
      display: flex;
      align-items: center;

      & + div {
        margin-left: 8px;
      }

      span {
        margin-left: 3px;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const DocumentTd = styled.td`
  min-width: 50px;
  text-align: center !important;
  overflow: visible !important;
  white-space: unset !important;

  ${props =>
    (props.situation === 1 || props.situation === 3 || props.situation === 4) &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  > div {
    position: relative;
  }
`;

export const Feedback = styled(Form)`
  margin: 16px 0;

  display: flex;
  flex-direction: column;

  section {
    align-items: flex-end;
  }
`;

export const SendFeedback = styled.div`
  margin-left: 16px;

  display: flex;
  align-items: center;

  .feedback_message {
    width: 40vw;

    textarea {
      width: 40vw;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 7px;
    border: 1px solid ${({ theme }) => theme.colors.success};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;

export const UploadFile = styled.div`
  display: flex;
  align-items: center;
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 30vw;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    height: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    background: transparent;
  }
`;

export const File = styled.div`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: 16px;
    opacity: 1;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }
  }
`;

export const FeedbackItem = styled.section`
  margin-top: 16px;
  position: relative;

  display: flex;

  div {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
      opacity: 0.7;
    }

    input {
      height: 30px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      background: transparent;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    button {
      background: none;
      margin-right: 8px;
      padding: 2px;
      transition: color 0.2s;
      color: ${({ theme }) => theme.colors.error};

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: ${({ theme }) => theme.hover.error};
      }
    }
  }

  div + div {
    margin-left: 16px;
  }

  .delete {
    height: 40px;
    padding-top: 20px;

    display: flex;
    justify-content: center;
  }

  .date {
    margin-left: 0;
    width: 15%;
  }

  .user {
    width: 15%;
  }

  .file {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    > aside {
      display: flex;
      flex-direction: column;

      button {
        background: none;
        color: ${({ theme }) => theme.colors.blue};
        padding: 2px;
        transition: color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    > div {
      flex: 1;

      margin-left: 8px;
    }
  }

  .content {
    width: 40%;
  }
`;

export const Situation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 175px;
  height: 100%;
  color: ${({ theme }) => theme.colors.primary};

  > button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  .option {
    width: 26px;
    height: 26px;

    padding: 2px;
    border: 2px solid #aaa;
    color: #aaa;
    border-radius: 50%;

    div {
      height: 100%;
      width: 100%;
      background: #aaa;
      border-radius: 50%;
    }
  }

  .waiting {
    border-color: #9dd3fe;
    color: #9dd3fe;

    div {
      background: #9dd3fe;
    }
  }

  .pending {
    border-color: #e53935;
    color: #e53935;
  }

  .recived {
    border-color: #006229;
    color: #006229;
  }

  .released {
    border-color: #9dd3fe;
    color: #9dd3fe;
  }
`;

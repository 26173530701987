import React from 'react';

import {
  FaSort,
  FaSortDown,
  FaSortUp,
  FaThumbsDown,
  FaThumbsUp,
  FaEquals,
} from 'react-icons/fa';
import { Content } from './styles';
import TableContainer from '../TableContainer';

const SimpleTable = ({ tbody, thead, click }) => {
  return (
    <Content>
      <TableContainer>
        <thead>
          <tr>
            {thead.map((item, index) => (
              <th
                id={item.id}
                className={item.className}
                onClick={() => (item.sort ? click(index) : undefined)}
              >
                {`${item.title} `}
                {item.sort === 'desc' ? (
                  <FaSortDown />
                ) : item.sort === 'asc' ? (
                  <FaSortUp />
                ) : item.sort === 'na' ? (
                  <FaSort />
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbody.map((item, index) => (
            <tr key={index} className="hover">
              <td className="situation">
                {item.situation === 1 ? (
                  <>
                    <FaThumbsUp color="#006229" />
                    <span style={{ marginLeft: '5px', color: '#006229' }}>
                      Enviado
                    </span>
                  </>
                ) : item.situation === 0 ? (
                  <>
                    <FaEquals color="#485365" />
                    <span style={{ marginLeft: '5px', color: '#485365' }}>
                      Em espera
                    </span>
                  </>
                ) : (
                  <>
                    <FaThumbsDown color="#E53935" />
                    <span style={{ marginLeft: '5px', color: '#E53935' }}>
                      Não enviado
                    </span>
                  </>
                )}
              </td>
              <td className="date">{item.date}</td>
              <td className="hour">{item.hour}</td>
              <td className="date">{item.send_message}</td>
              <td className="hour">{item.send_hour}</td>
              <td className="sender">{item.sender?.name}</td>
              <td className="cliente">{item.client.name}</td>
              <td className="resiver">{item.resiver?.name}</td>
              <td className="phone">
                {item.resiver?.contacts[0]
                  ? item.resiver?.contacts[0].content
                  : ''}
              </td>
              <td className="message">{item.message}</td>
            </tr>
          ))}
        </tbody>
      </TableContainer>
    </Content>
  );
};

export default SimpleTable;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;

  margin: 0;
  padding: 0;
  background-color: #eee;
`;

export const Header = styled.header`
  width: 100%;
  height: 40px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) => theme.colors.blue};

  font-size: 1rem;
  color: #fff;

  @media (max-width: 650px) {
    justify-content: center;

    > span {
      display: none;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 1rem;
    margin-left: 8px;
  }

  img {
    border: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
`;

export const Content = styled.main`
  min-height: calc(100% - 112px);
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary};
  margin: 16px 20vw;

  h1 {
    color: ${({ theme }) => theme.colors.blue};
    text-align: center;
  }

  h1:first-child {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 650px) {
    margin: 16px 5vw;
    min-height: calc(100% - 159px);
  }
`;

export const Documents = styled.div`
  margin: 50px 0;

  .docs {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }

  span {
    margin: 8px 0 0 16px;
  }

  .all-recived {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #006229;

    h3 {
      margin-left: 8px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 8px;

  h2 {
    margin-right: 8px;
  }
`;

export const Phases = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Phase = styled.div`
  display: flex;
  flex-direction: row;

  .route {
    width: 50px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .end-route {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;
    min-height: 30px;
    border: 3px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
  }

  .success {
    border-color: #006229;
  }

  .progress {
    border-color: #f4c306;
  }

  .pending {
    border-color: #e53935;
  }

  .released {
    border-color: #ccc;
  }

  .end {
    background: #f4c306;
    border-color: #f4c306;
  }

  .start {
    background: #006229;
    border-color: #006229;
  }

  .line {
    height: 100%;
    margin: 8px 0;
    width: 0;
    border-left: 6px solid ${({ theme }) => theme.colors.primary};
    border-radius: 8px;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;

    h4 {
      margin: 7px 0;
    }

    span,
    button {
      margin: 5px 0 0 16px;
    }

    button {
      background: transparent;
      border: 0;
      color: ${({ theme }) => theme.colors.blue};
      font-weight: bold;
      display: flex;

      svg {
        margin-right: 4px;
      }
    }
  }
`;

export const Footer = styled.footer`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  background: ${({ theme }) => theme.colors.blue};

  font-size: 1rem;
  color: #fff;

  div {
    margin: 10px;
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;

    div {
      margin: 5px 0;
    }
  }
`;

export const Error = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  font-weight: bold;
  color: #e53935;
`;

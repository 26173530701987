import React, { useState, useEffect } from 'react';
import {
  FaCheck,
  FaCheckCircle,
  FaClock,
  FaEnvelope,
  FaExclamationCircle,
  FaMinus,
  FaPhone,
  FaTimesCircle,
  FaEye,
} from 'react-icons/fa';
import { toast } from 'react-toastify';

import base64 from 'base-64';
import { format, parseISO } from 'date-fns';
import logo from '~/assets/building.png';
import Loading from '~/components/Loading';
import api from '~/services/api';
import formatContact from '~/util/formatContact';

import {
  Container,
  Header,
  Logo,
  Content,
  Documents,
  Title,
  Phases,
  Phase,
  Footer,
  Error,
} from './styles';

const ProcessSituation = ({ match }) => {
  const { id } = match.params;
  const [process, setProcess] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [finalPrevision, setFinalPrevision] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(
          `/process-situation/${base64.decode(id)}`
        );

        if (response.data) {
          let previsionAux = null;
          let formattedPrevision = '';
          const { data } = response;

          data.phases = data.phases.map(item => {
            let conclusion_date = '';

            if (item.situation === 3) {
              conclusion_date = item.conclusion_date
                ? format(parseISO(item.conclusion_date), 'dd/MM/yyyy')
                : '';
            }

            if (item.situation !== 3 && item.situation !== 4) {
              formattedPrevision = item.prevision_date
                ? format(parseISO(item.prevision_date), 'dd/MM/yyyy')
                : '';

              if (previsionAux < parseISO(item.prevision_date)) {
                previsionAux = parseISO(item.prevision_date);
              }
            }

            return {
              ...item,
              conclusion_date,
              formattedPrevision,
            };
          });

          setFinalPrevision(
            previsionAux
              ? `Previsão ${format(previsionAux, 'dd/MM/yyyy')}`
              : 'Finalizado'
          );

          const { company: companyData } = data;

          if (companyData.phone) {
            companyData.phone =
              companyData.phone > 9999999999
                ? formatContact(3, companyData.phone)
                : formatContact(2, companyData.phone);
          } else {
            companyData.phone = '';
          }

          setProcess(data);
          setCompany(companyData);
        } else {
          toast.error('Falha ao buscar os dados do processo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar os dados do processo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    })();
  }, [id]);

  const openFile = async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          {process && company ? (
            <>
              <Header>
                <Logo>
                  <img
                    src={company && company.logo ? company.logo_url : logo}
                    alt="Logo"
                  />
                  <span>{company?.name || ''}</span>
                </Logo>
                <span>
                  {process.situation === 0 && 'Em andamento'}
                  {process.situation === 1 && 'Concluído'}
                  {process.situation === 2 && 'Cancelado'}
                </span>
              </Header>

              <Content>
                <h1>{process.model.title}</h1>
                <h1>{process.client.name}</h1>
                <Documents>
                  {process.documents.length === 0 ? (
                    <div className="all-recived">
                      <FaCheck size={18} />
                      <h3>Todos os documentos já foram recebidos.</h3>
                    </div>
                  ) : (
                    <>
                      <Title>
                        <h2>Documentos Pendentes</h2>
                      </Title>

                      <div className="docs">
                        {process.documents.map(item => (
                          <span key={item.id}>{item.document}</span>
                        ))}
                      </div>
                    </>
                  )}
                </Documents>

                <Phases>
                  <Title>
                    <h2>Etapas</h2>
                  </Title>

                  <Phase>
                    <section className="route">
                      <div
                        className={`circle ${
                          process.situation ? 'start' : 'end'
                        }`}
                      />
                      <div className="line" />
                    </section>
                    <section className="info">
                      <h4>Conclusão do Processo</h4>
                      <span>{finalPrevision}</span>
                    </section>
                  </Phase>

                  {process.phases.map(item => (
                    <Phase key={item.id}>
                      <section className="route">
                        {item.situation === 0 && <div className="circle" />}
                        {item.situation === 1 && (
                          <div className="circle progress">
                            <FaClock color="#f4c306" />
                          </div>
                        )}
                        {item.situation === 2 && (
                          <div className="circle pending">
                            <FaExclamationCircle color="#E53935" />
                          </div>
                        )}
                        {item.situation === 3 && (
                          <div className="circle success">
                            <FaCheckCircle color="#006229" />
                          </div>
                        )}
                        {item.situation === 4 && (
                          <div className="circle released">
                            <FaMinus color="#ccc" />
                          </div>
                        )}
                        <div className="line" />
                      </section>
                      <section className="info">
                        <h4>{item.document}</h4>

                        {item.file && (
                          <button
                            type="button"
                            onClick={() => openFile(item.file_url)}
                          >
                            <FaEye /> Clique aqui para visualizar
                          </button>
                        )}

                        {item.situation === 0 && (
                          <span>{`Previsão ${item.formattedPrevision}`}</span>
                        )}
                        {item.situation === 1 && (
                          <>
                            <span>Em andamento</span>
                            <span>{`Previsão ${item.formattedPrevision}`}</span>
                          </>
                        )}
                        {item.situation === 2 && <span>Pendente</span>}
                        {item.situation === 3 && (
                          <span>Realizado {item.conclusion_date || ''}</span>
                        )}
                        {item.situation === 4 && <span>Desobrigado</span>}

                        {item.obs && <span>Obs.: {item.obs}</span>}
                      </section>
                    </Phase>
                  ))}
                  <Phase>
                    <section className="route">
                      <div className="circle start" />
                    </section>
                    <section className="info">
                      <h4>Início do processo</h4>
                    </section>
                  </Phase>
                </Phases>
              </Content>

              <Footer>
                <div>
                  <span>{company.name}</span>
                </div>
                <div>
                  <FaPhone color="#fff" />
                  <span>{company.phone}</span>
                </div>
                <div>
                  <FaEnvelope color="#fff" />
                  <span>{company.email}</span>
                </div>
              </Footer>
            </>
          ) : (
            <Error>
              <span>Ocorreu algum erro e por isso</span>
              <span>não conseguimos encontrar o processo!</span>
              <FaTimesCircle color="#E53935" size={50} />
            </Error>
          )}
        </>
      )}
    </Container>
  );
};

export default ProcessSituation;

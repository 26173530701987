import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { Container, Icon, Header, Content } from './styles';

const ConfirmWindow = ({ onClick, onClose, message }) => {
  return (
    <Container>
      <Icon>
        <FaExclamationTriangle size={38} color="#01579b" />
      </Icon>
      <Content>
        <Header>
          <strong>Atenção</strong>
        </Header>
        <span>
          <strong>Tem certeza?</strong> {message}
        </span>
        <div>
          <button
            type="button"
            onClick={() => {
              onClose();
              onClick();
            }}
          >
            Sim
          </button>
          <button type="button" onClick={onClose}>
            Não
          </button>
        </div>
      </Content>
    </Container>
  );
};

export default ConfirmWindow;

ConfirmWindow.defaultProps = {
  message: 'Todos os dados não salvos serão perdidos com esta ação.',
};

ConfirmWindow.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import {
  FaHandshake,
  FaTimes,
  FaEye,
  FaPlus,
  FaEraser,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import formatDocument from '~/util/formatDocument';
import formatContact from '~/util/formatContact';

import { Input, Select } from '~/components/Form';
import { TableLoading } from '~/components/Table';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  Table,
  DetailsContainer,
} from '~/styles/components';
import {
  Filter,
  Content,
  RelatedPeopleInfo,
  Addresses,
  Address,
  Contacts,
  Tags,
  Audit,
} from './styles';
import PermissionComponent from '~/components/PermissionComponent';

const List = () => {
  const { company } = useAuth();

  const { state } = useLocation();

  let matchParamsId;

  if (state) {
    matchParamsId = state.id;
  } else {
    matchParamsId = null;
  }

  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [showTable, setShowTable] = useState(true);

  const [relatedPeoples, setRelatedPeoples] = useState([]);
  const [relatedPeople, setRelatedPeople] = useState({});
  const [relatedPeopleIndex, setRelatedPeopleIndex] = useState(0);
  const [totalRelatedPeoples, setTotalRelatedPeoples] = useState(0);

  const [currentPage, setCurrentPage] = useState(() => {
    const currentPageStorage = sessionStorage.getItem(
      '@Diretiva:related-people:page'
    );

    if (currentPageStorage) {
      return Number(currentPageStorage);
    }

    return 1;
  });
  const [totalPages, setTotalPages] = useState(1);

  const [nameSearch, setNameSearch] = useState(() => {
    const nameStorage = localStorage.getItem(
      '@Diretiva:related-people:filter:name'
    );

    if (nameStorage) {
      return nameStorage;
    }

    return '';
  });
  const [selectedSituation, setSelectedSituation] = useState(() => {
    const situationStorage = localStorage.getItem(
      '@Diretiva:related-people:filter:situation'
    );

    if (situationStorage) {
      return JSON.parse(situationStorage);
    }

    return { value: '', label: 'Todos' };
  });

  const loadRelatedPeoples = useCallback(async () => {
    if (company) {
      try {
        if (matchParamsId) {
          setShowTable(false);
          setLoading(true);

          const response = await api.get(`relationships/${matchParamsId}`);

          const { data } = response;

          const formattedRelatedPeople = {
            ...data,
            birthday: data.birthday
              ? format(parseISO(data.birthday), 'dd/MM/yyyy')
              : '',
            end_day: data.end_day
              ? format(parseISO(data.end_day), 'dd/MM/yyyy')
              : '',
            document: data.document
              ? formatDocument(data.document_type, data.document)
              : '',
            addresses: data?.addresses?.map(address => ({
              ...address,
              complement:
                address.complement === 'Array' ? '' : address.complement,
            })),
            contacts: data?.contacts?.map(contact => ({
              ...contact,
              content: contact.content
                ? formatContact(
                    contact.type,
                    contact.type !== 1
                      ? contact.content.replace(/[^0-9]+/g, '')
                      : contact.content
                  )
                : '',
            })),
            audit: data?.audit?.map(audit => ({
              ...audit,
              formatted_audit_date: format(
                parseISO(audit.created_at),
                "dd/MM/yyyy 'às' HH:mm"
              ),
            })),
          };

          setRelatedPeople(formattedRelatedPeople);
        } else {
          setShowTable(true);
          setLoading(true);

          const response = await api.get('relationships', {
            params: {
              company_id: company.id,
              page: currentPage,
              type: 2,
              search: nameSearch,
              active: selectedSituation.value,
            },
          });

          if (response.data.docs.length > 0) {
            const formattedRelatedPeoples = response.data.docs.map(item => ({
              ...item,
              birthday: item.birthday
                ? format(parseISO(item.birthday), 'dd/MM/yyyy')
                : '',
              end_day: item.end_day
                ? format(parseISO(item.end_day), 'dd/MM/yyyy')
                : '',
              document: item.document
                ? formatDocument(item.document_type, item.document)
                : '',
              addresses: item?.addresses?.map(address => ({
                ...address,
                complement:
                  address.complement === 'Array' ? '' : address.complement,
              })),
              contacts: item?.contacts?.map(contact => ({
                ...contact,
                content: contact.content
                  ? formatContact(
                      contact.type,
                      contact.type !== 1
                        ? contact.content.replace(/[^0-9]+/g, '')
                        : contact.content
                    )
                  : '',
              })),
              audit: item?.audit?.map(audit => ({
                ...audit,
                formatted_audit_date: format(
                  parseISO(audit.created_at),
                  "dd/MM/yyyy 'às' HH:mm"
                ),
              })),
            }));

            setRelatedPeoples(formattedRelatedPeoples);
            if (!matchParamsId) {
              setRelatedPeople(formattedRelatedPeoples[0]);
            }

            setTotalPages(response.data.pages);
            setTotalRelatedPeoples(response.data.total);
          } else {
            toast.warn('Nenhuma pessoa relacionada foi encontrada.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      } catch (err) {
        toast.error(
          `${err.response.data.message ||
            'Falha ao buscar pessoas relacionadas.'}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      } finally {
        setLoading(false);
      }
    }
  }, [company, currentPage, nameSearch, selectedSituation, matchParamsId]);

  useEffect(() => {
    loadRelatedPeoples();
  }, [loadRelatedPeoples]);

  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Ativo' },
      { value: 0, label: 'Inativo' },
    ];
  }, []);

  const handleView = useCallback(() => {
    if (matchParamsId) {
      history.push('/related-people', { id: null });
    }
    if (relatedPeoples.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, relatedPeoples, matchParamsId]);

  const handlePrevItem = useCallback(() => {
    if (relatedPeopleIndex !== 0) {
      setRelatedPeopleIndex(relatedPeopleIndex - 1);
      setRelatedPeople(relatedPeoples[relatedPeopleIndex - 1]);
    } else {
      setRelatedPeopleIndex(relatedPeoples.length - 1);
      setRelatedPeople(relatedPeoples[relatedPeoples.length - 1]);
    }
  }, [relatedPeopleIndex, relatedPeoples]);

  const handleNextItem = useCallback(() => {
    if (relatedPeopleIndex !== relatedPeoples.length - 1) {
      setRelatedPeopleIndex(relatedPeopleIndex + 1);
      setRelatedPeople(relatedPeoples[relatedPeopleIndex + 1]);
    } else {
      setRelatedPeopleIndex(0);
      setRelatedPeople(relatedPeoples[0]);
    }
  }, [relatedPeopleIndex, relatedPeoples]);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`relationships/${id}`);

        handleView();

        loadRelatedPeoples();

        toast.success('Pessoa relacionada deletada com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar pessoa relacionada.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [handleView, loadRelatedPeoples]
  );

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(relatedPeople.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, relatedPeople]);

  const handleFilter = useCallback(
    ({ name, situation }) => {
      setCurrentPage(1);
      localStorage.setItem('@Diretiva:related-people:page', 1);

      setNameSearch(name);
      localStorage.setItem('@Diretiva:related-people:filter:name', name);

      setSelectedSituation(
        situationOptions.find(option => option.value === situation)
      );
      localStorage.setItem(
        '@Diretiva:related-people:filter:situation',
        JSON.stringify(
          situationOptions.find(option => option.value === situation)
        )
      );
    },
    [situationOptions]
  );

  const handleResetFilter = useCallback(() => {
    setCurrentPage(1);
    localStorage.removeItem('@Diretiva:related-people:page');

    filterRef.current.reset();

    setNameSearch('');
    localStorage.removeItem('@Diretiva:related-people:filter:name');

    filterRef.current.setFieldValue('situation', {
      value: '',
      label: 'Todos',
    });
    setSelectedSituation({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:related-people:filter:situation');
  }, []);

  const handleDetails = useCallback(
    (id, index) => {
      const relatedPeopleDetails = relatedPeoples.find(item => item.id === id);

      setRelatedPeople(relatedPeopleDetails);
      setRelatedPeopleIndex(index);
      handleView();
    },
    [relatedPeoples, handleView]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
        sessionStorage.setItem('@Diretiva:related-people:page', 1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
        sessionStorage.setItem('@Diretiva:related-people:page', totalPages);
      } else {
        setCurrentPage(page);
        sessionStorage.setItem('@Diretiva:related-people:page', page);
      }
    },
    [totalPages]
  );

  return (
    <Container>
      <Header>
        <div>
          <FaHandshake />
          <h1>Pessoas Relacionadas</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={handleView}>
          <FaEye />
          <span>Visualização</span>
        </button>
        <Link to={{ pathname: '/related-people/new', state: { id: null } }}>
          <FaPlus />
          <span>Novo</span>
        </Link>
        {showTable ? (
          <button type="button" onClick={handleResetFilter}>
            <FaEraser />
            <span>Limpar filtros</span>
          </button>
        ) : (
          <>
            {totalRelatedPeoples !== 0 && (
              <>
                <PermissionComponent level={5}>
                  <Link
                    to={{
                      pathname: '/related-people/edit',
                      state: { id: relatedPeople.id },
                    }}
                  >
                    <FaEdit />
                    <span>Editar</span>
                  </Link>
                </PermissionComponent>
                <PermissionComponent level={9}>
                  <button type="button" onClick={confirmDelete}>
                    <FaTrash />
                    <span>Excluir</span>
                  </button>
                </PermissionComponent>
                {!matchParamsId && (
                  <div>
                    <button type="button" onClick={handlePrevItem}>
                      <FaChevronLeft />
                    </button>
                    {totalRelatedPeoples > 25 ? (
                      <span>{relatedPeopleIndex + 1} de 25</span>
                    ) : (
                      <span>
                        {relatedPeopleIndex + 1} de {totalRelatedPeoples}
                      </span>
                    )}
                    <button type="button" onClick={handleNextItem}>
                      <FaChevronRight />
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Controls>
      {showTable && (
        <Filter ref={filterRef} onSubmit={handleFilter}>
          <Input
            name="name"
            className="name"
            label="Nome"
            defaultValue={nameSearch}
          />
          <Select
            label="Situação"
            name="situation"
            className="situation"
            options={situationOptions}
            defaultValue={selectedSituation}
          />

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>
      )}
      {loading || !company ? (
        <TableLoading />
      ) : (
        <Content className="content">
          {showTable ? (
            <Table>
              <thead>
                <tr>
                  <th className="document-table">CPF</th>
                  <th className="name-table">Nome</th>
                  <th className="address-table">Endereço</th>
                  <th className="number-table">Nº</th>
                  <th className="city-table">Cidade</th>
                </tr>
              </thead>
              <tbody>
                {relatedPeoples?.map((item, index) => (
                  <tr
                    key={item.id}
                    className="hover"
                    onClick={() => handleDetails(item.id, index)}
                  >
                    <td className="document-table">{item?.document}</td>
                    <td className="name-table">{item?.name}</td>
                    <td className="address-table">
                      {item?.addresses[0]?.street}
                    </td>
                    <td className="number-table">
                      {item?.addresses[0]?.number}
                    </td>
                    <td className="city-table">{item?.addresses[0]?.city}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <DetailsContainer>
              <RelatedPeopleInfo>
                <h4>INFORMAÇÕES BÁSICAS</h4>
                <section>
                  <div className="document">
                    <label>CPF</label>
                    <input
                      name="document"
                      value={relatedPeople?.document || ''}
                      readOnly
                    />
                  </div>
                  <div className="name">
                    <label>Nome</label>
                    <input
                      name="name"
                      value={relatedPeople?.name || ''}
                      readOnly
                    />
                  </div>
                  <div className="treatment_name">
                    <label>Tratamento</label>
                    <input
                      name="treatment_name"
                      value={relatedPeople?.treatment_name || ''}
                      readOnly
                    />
                  </div>
                  <div className="portal_password">
                    <label>Senha do Novo Portal</label>
                    <input
                      name="portal_password"
                      value={relatedPeople?.portal_password || ''}
                      readOnly
                    />
                  </div>
                </section>
                <section>
                  <div className="birthday">
                    <label>Nascimento</label>
                    <input
                      name="birthday"
                      value={relatedPeople?.birthday}
                      readOnly
                    />
                  </div>
                  <div className="protocol_preference">
                    <label>Preferência de protocolo</label>
                    <input
                      name="protocol_preference"
                      value={relatedPeople?.protocol_preference_label || ''}
                      readOnly
                    />
                  </div>
                  <div className="active">
                    <label>Situação</label>
                    <input
                      name="active"
                      value={relatedPeople?.active ? 'Ativo' : 'Inativo'}
                      readOnly
                    />
                  </div>
                  {!relatedPeople?.active ? (
                    <div className="end_day">
                      <label>Encerramento</label>
                      <input
                        name="end_day"
                        value={relatedPeople?.end_day || ''}
                        readOnly
                      />
                    </div>
                  ) : (
                    <div className="end_day">
                      <label>Enviar WhatsApp</label>
                      <input
                        name="WhatsAppConected"
                        value={
                          relatedPeople.chat_id == 1
                            ? 'Conectado'
                            : 'Não conectado'
                        }
                        readOnly
                      />
                    </div>
                  )}
                </section>
              </RelatedPeopleInfo>
              {relatedPeople?.addresses?.length > 0 && (
                <Addresses>
                  <h4>ENDEREÇOS</h4>
                  {relatedPeople.addresses?.map(address => (
                    <Address key={address.id}>
                      <section>
                        <div className="zipcode">
                          <label>CEP</label>
                          <input
                            name="zipcode"
                            value={address?.zipcode || ''}
                            readOnly
                          />
                        </div>
                        <div className="street">
                          <label>Endereço</label>
                          <input
                            name="street"
                            value={address?.street || ''}
                            readOnly
                          />
                        </div>
                        <div className="number">
                          <label>Nº</label>
                          <input
                            name="number"
                            value={address?.number || ''}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        <div className="complement">
                          <label>Complemento</label>
                          <input
                            name="complement"
                            value={address?.complement || ''}
                            readOnly
                          />
                        </div>
                        <div className="neighborhood">
                          <label>Bairro</label>
                          <input
                            name="neighborhood"
                            value={address?.neighborhood || ''}
                            readOnly
                          />
                        </div>
                        <div className="city">
                          <label>Cidade</label>
                          <input
                            name="city"
                            value={address.city || ''}
                            readOnly
                          />
                        </div>
                        <div className="state">
                          <label>Estado</label>
                          <input
                            name="state"
                            value={address.state_label || ''}
                            readOnly
                          />
                        </div>
                      </section>
                    </Address>
                  ))}
                </Addresses>
              )}
              {relatedPeople?.contacts?.length > 0 && (
                <Contacts>
                  <h4>CONTATOS</h4>
                  {relatedPeople.contacts?.map(contact => (
                    <section key={contact.id}>
                      <div className="contact_type">
                        <label>Tipo</label>
                        <input
                          name="contact_type"
                          value={contact.type_label || ''}
                          readOnly
                        />
                      </div>
                      <div className="content">
                        <label>{contact.type_label}</label>
                        <input
                          name="content"
                          value={contact.content || ''}
                          readOnly
                        />
                      </div>
                    </section>
                  ))}
                </Contacts>
              )}
              {relatedPeople?.tags?.length > 0 && (
                <Tags>
                  <h4>TAGS</h4>
                  {relatedPeople?.tags?.map(tag => (
                    <section key={tag.id}>
                      <div className="tag">
                        <label>Tag</label>
                        <input name="tag" value={tag?.tag || ''} readOnly />
                      </div>
                    </section>
                  ))}
                </Tags>
              )}
              {relatedPeople?.audit?.length > 0 && (
                <Audit>
                  <h4>LOGS</h4>
                  {relatedPeople?.audit?.map(audit => (
                    <section key={audit.id}>
                      <div className="date">
                        <label>Data/Hora</label>
                        <input
                          name="formatted_audit_date"
                          value={audit?.formatted_audit_date || ''}
                          readOnly
                        />
                      </div>
                      <div className="user">
                        <label>Usuário</label>
                        <input
                          name="audit_user"
                          value={audit?.user.short_name || ''}
                          readOnly
                        />
                      </div>
                      <div className="action">
                        <label>Ação</label>
                        <input
                          name="action"
                          value={audit?.action_label || ''}
                          readOnly
                        />
                      </div>
                    </section>
                  ))}
                </Audit>
              )}
            </DetailsContainer>
          )}
        </Content>
      )}
      <Pagination
        loading={loading ? 1 : 0}
        currentPage={currentPage}
        pages={totalPages}
        totalDocs={totalRelatedPeoples}
        handlePage={handlePage}
      />
      {deleteLoading && <Loading />}
    </Container>
  );
};

export default List;

import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaEye,
  FaPlus,
  FaEdit,
  FaTrash,
  FaTimes,
  FaDownload,
  FaTh,
  FaChevronLeft,
  FaChevronRight,
  FaCopy,
} from 'react-icons/fa';
import fileDownload from 'js-file-download';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import {
  Container,
  Header,
  Controls,
  Content,
  DetailsContainer,
} from './styles';

const List = ({ setIsOpen, handleChangeId, setIsForm }) => {
  const { user, company, companyUser, companyUsers } = useAuth();

  const [processTypesLoading, setProcessTypesLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showTable, setShowTable] = useState(1);

  const [processTypes, setProcessTypes] = useState([]);

  const [processType, setProcessType] = useState();
  const [processTypeIndex, setProcessTypeIndex] = useState(0);

  const TypesQuestion = [
    { value: 0, label: 'Texto' },
    { value: 1, label: 'Inteiro' },
    { value: 2, label: 'Decimal' },
    { value: 3, label: 'Moeda' },
  ];

  const [usersOptions, setUsersOptions] = useState([]);
  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.unshift({
        value: user.id,
        label: user.short_name,
        active: true,
      });

      options.unshift({
        value: null,
        label: 'Nenhum',
      });
      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  useEffect(() => {
    async function loadModels() {
      if (user && company) {
        try {
          setProcessTypesLoading(true);

          const response = await api.get(`/process-models`, {
            params: {
              company_id: company.id,
            },
          });

          if (response.data.length > 0) {
            setProcessTypes(response.data);
            setProcessType(response.data[0]);
          } else {
            setProcessTypes([]);
            setProcessType({});
            toast.warn('Nenhum modelo foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setProcessTypesLoading(false);
        } catch {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setProcessTypesLoading(false);
        }
      }
    }

    loadModels();
  }, [company, user]);

  const alterView = useCallback(() => {
    if (processTypes.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, processTypes.length]);

  const getDetails = useCallback(
    async index => {
      setProcessType(processTypes[index]);
      setProcessTypeIndex(index);
      alterView();
    },
    [processTypes, alterView]
  );

  const handlePrevItem = useCallback(async () => {
    if (processTypeIndex !== 0) {
      setProcessTypeIndex(processTypeIndex - 1);
      setProcessType(processTypes[processTypeIndex - 1]);
    } else {
      setProcessTypeIndex(processTypes.length - 1);
      setProcessType(processTypes[processTypes.length - 1]);
    }
  }, [processTypeIndex, processTypes]);

  const handleNextItem = useCallback(async () => {
    if (processTypeIndex !== processTypes.length - 1) {
      setProcessTypeIndex(processTypeIndex + 1);
      setProcessType(processTypes[processTypeIndex + 1]);
    } else {
      setProcessTypeIndex(0);
      setProcessType(processTypes[0]);
    }
  }, [processTypeIndex, processTypes]);

  const handleEdit = useCallback(() => {
    if ((user, companyUser)) {
      if (companyUser.level < 9) {
        toast.warn('Somente o administrador da empresa pode editá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        handleChangeId(processType.id);
        setIsForm(true);
      }
    }
  }, [processType, user, companyUser, handleChangeId, setIsForm]);

  const handleDelete = useCallback(
    async id => {
      try {
        setLoading(true);

        await api.delete(`/process-models/${id}`);

        setProcessTypes(oldAppoinments =>
          oldAppoinments.filter(item => item.id !== id)
        );

        alterView();

        setProcessType(processTypes[0]);
        setProcessTypeIndex(0);

        toast.success('Compromisso deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar compromisso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    },
    [alterView, processTypes]
  );

  const confirmDelete = useCallback(() => {
    if (user && companyUser) {
      if (companyUser.level < 9) {
        toast.warn('Somente o administrador da empresa pode excluí-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmWindow
                onClick={() => handleDelete(processType.id)}
                onClose={onClose}
              />
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  }, [handleDelete, processType, user, companyUser]);

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  const downloadFile = useCallback(async (blobName, fileName) => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }, []);

  async function copyModel() {
    if ((user, companyUser)) {
      if (companyUser.level < 9) {
        toast.warn(
          'Somente o administrador da empresa pode criar uma copia de modelo.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      } else {
        const response = await api.post(
          `/process-models/copy-models/${processType.id}`
        );

        if (response.status !== 201) {
          toast.error('Nao foi possivel fazer a copia deste modelo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.success('Compromisso copiado com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }
  }

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaTh size={20} color="#44546a" />
            <h1>Modelos de processos</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>

        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>
          <button type="button" onClick={setIsForm}>
            <FaPlus />
            <span>Novo</span>
          </button>
          {!showTable && (
            <>
              <button type="button" onClick={handleEdit}>
                <FaEdit />
                <span>Editar</span>
              </button>
              <button type="button" onClick={confirmDelete}>
                <FaTrash />
                <span>Excluir</span>
              </button>
              {companyUser.level >= 9 ? (
                <button type="button" onClick={copyModel}>
                  <FaCopy />
                  <span>Criar Modelo</span>
                </button>
              ) : null}
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>

                <span>
                  {processTypeIndex + 1} de {processTypes.length}
                </span>

                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>
        {processTypesLoading ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="icon" />
                    <th className="name">Título</th>
                  </tr>
                </thead>
                <tbody>
                  {processTypes.map((item, index) => (
                    <tr
                      key={String(item.id)}
                      onClick={() => getDetails(index)}
                      className="hover"
                    >
                      <td className="icon">
                        <FontAwesomeIcon
                          icon={item.icon.name}
                          color="#44546a"
                        />
                      </td>
                      <td className="name">{item.title}</td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer>
                {processType && (
                  <>
                    <h4>MODELO DE PROCESSOS</h4>
                    <section>
                      <div className="icon">
                        <FontAwesomeIcon
                          icon={processType.icon.name}
                          size="lg"
                          color="#44546a"
                        />
                      </div>

                      <div className="title">
                        <label>Título</label>
                        <input
                          name="title"
                          value={processType.title || ''}
                          readOnly
                        />
                      </div>

                      <div className="menu-title">
                        <label>Título do menu</label>
                        <input
                          name="menu_title"
                          value={processType.menu_title || ''}
                          readOnly
                        />
                      </div>

                      <div className="type">
                        <label>Responsável padrão</label>
                        <input
                          name="menu_title"
                          value={
                            processType.model_responsible_id
                              ? usersOptions.find(
                                  element =>
                                    element.value ===
                                    processType.model_responsible_id
                                )?.label
                              : ''
                          }
                          readOnly
                        />
                      </div>

                      <div className="type">
                        <label>Tipo de modelo</label>
                        {processType.model_type === 0 && (
                          <input
                            name="model_type"
                            value="Modelo padrão"
                            readOnly
                          />
                        )}
                        {processType.model_type === 1 && (
                          <input
                            name="model_type"
                            value="Modelo trabalhista"
                            readOnly
                          />
                        )}
                        {processType.model_type === 2 && (
                          <input
                            name="model_type"
                            value="Controle de vencimento"
                            readOnly
                          />
                        )}
                      </div>

                      {processType.model_type === 0 && (
                        <div className="checkbox">
                          <label>Disparar etapa seguinte</label>
                          <input
                            name="start_next_phase"
                            checked={processType.start_next_phase || false}
                            type="checkbox"
                            disabled
                          />
                        </div>
                      )}
                    </section>
                    <section className="sector">
                      <div className="checkbox">
                        <label>Trabalhista</label>
                        <input
                          name="labor"
                          checked={processType.labor}
                          type="checkbox"
                          disabled
                        />
                      </div>
                      <div className="checkbox">
                        <label>Tributário</label>
                        <input
                          name="tributary"
                          checked={processType.tributary}
                          type="checkbox"
                          disabled
                        />
                      </div>
                      <div className="checkbox">
                        <label>Contábil</label>
                        <input
                          name="acounting"
                          checked={processType.accounting}
                          type="checkbox"
                          disabled
                        />
                      </div>
                      <div className="checkbox">
                        <label>Financeiro</label>
                        <input
                          name="financial"
                          checked={processType.financial}
                          type="checkbox"
                          disabled
                        />
                      </div>
                      <div className="checkbox">
                        <label>Administração</label>
                        <input
                          name="administration"
                          checked={processType.administration}
                          type="checkbox"
                          disabled
                        />
                      </div>
                    </section>

                    {processType.model_type === 0 && (
                      <section>
                        <div className="type">
                          <label>Tipo processo</label>
                          {processType.process_type === 0 && (
                            <input
                              name="process_type"
                              value="Processo padrão"
                              readOnly
                            />
                          )}
                          {processType.process_type === 1 && (
                            <input
                              name="process_type"
                              value="Processos com 1 sócio"
                              readOnly
                            />
                          )}
                          {processType.process_type === 2 && (
                            <input
                              name="process_type"
                              value="Processos com vários sócios"
                              readOnly
                            />
                          )}
                        </div>
                      </section>
                    )}

                    {processType.model_type === 1 && (
                      <section>
                        <div className="type">
                          <label>Tipo processo</label>
                          {processType.process_type === 0 && (
                            <input
                              name="process_type"
                              value="Admissão"
                              readOnly
                            />
                          )}
                          {processType.process_type === 1 && (
                            <input
                              name="process_type"
                              value="Recisão"
                              readOnly
                            />
                          )}
                        </div>
                      </section>
                    )}

                    {processType.model_type === 2 && (
                      <section>
                        <div className="type">
                          <label>Tipo processo</label>
                          {processType.process_type === 0 && (
                            <input
                              name="process_type"
                              value="Padrão"
                              readOnly
                            />
                          )}
                          {processType.process_type === 1 && (
                            <input
                              name="process_type"
                              value="Empresas terceirizadas"
                              readOnly
                            />
                          )}
                          {processType.process_type === 2 && (
                            <input
                              name="process_type"
                              value="Empresas próprias"
                              readOnly
                            />
                          )}
                        </div>
                      </section>
                    )}

                    {processType.documents.length > 0 && (
                      <h4 className="description">DOCUMENTAÇÃO NECESSÁRIA</h4>
                    )}
                    {processType.documents.map((item, index) => (
                      <section key={index}>
                        {processType.model_type === 2 && (
                          <div className="abreviate">
                            <label>Abreviatura</label>
                            <input
                              name="abbreviation"
                              value={item.abbreviation || ''}
                              readOnly
                            />
                          </div>
                        )}
                        <div className="document">
                          <label>Documento</label>
                          <input
                            name="document"
                            value={item.document || ''}
                            readOnly
                          />
                        </div>

                        <div className="document">
                          <label>Observação</label>
                          <input name="obs" value={item.obs || ''} readOnly />
                        </div>

                        {processType.model_type === 0 &&
                          processType.process_type > 0 && (
                            <div className="type">
                              <label>Tipo do documento</label>
                              {item.quantitative_type === 0 && (
                                <input
                                  name="quantitative_type"
                                  value="para empresa"
                                  readOnly
                                />
                              )}
                              {item.quantitative_type === 1 && (
                                <input
                                  name="quantitative_type"
                                  value="para cada sócio"
                                  readOnly
                                />
                              )}
                              {item.quantitative_type === null && (
                                <input
                                  name="quantitative_type"
                                  value=""
                                  readOnly
                                />
                              )}
                            </div>
                          )}

                        {processType.model_type === 2 && (
                          <div className="type">
                            <label>Responsável</label>
                            <input
                              name="responsible_id"
                              value={
                                item.responsible_id
                                  ? usersOptions.find(
                                      element =>
                                        element.value === item.responsible_id
                                    ).label
                                  : 'Nenhum'
                              }
                              readOnly
                            />
                          </div>
                        )}
                        {processType.model_type === 2 &&
                          item.document_required && (
                            <div className="type">
                              <label>Documento</label>
                              <input
                                name="document_id"
                                value={item.doc.description}
                                readOnly
                              />
                            </div>
                          )}
                        {(processType.model_type !== 2 ||
                          !item.document_required) && (
                          <>
                            {item.file ? (
                              <div className="file">
                                <aside>
                                  <button
                                    type="button"
                                    onClick={() => openFile(item.file_url)}
                                    title="Visualizar arquivo"
                                  >
                                    <FaEye size={16} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      downloadFile(item.file_url, item.file)
                                    }
                                    title="Baixar arquivo"
                                  >
                                    <FaDownload size={16} />
                                  </button>
                                </aside>
                                <div>
                                  <label>Arquivo</label>
                                  <input
                                    name="file"
                                    value={item.file || ''}
                                    readOnly
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="no-file">
                                <label>Arquivo</label>
                                <input
                                  name="file"
                                  value="Sem arquivo"
                                  readOnly
                                />
                              </div>
                            )}
                          </>
                        )}

                        <div className="type">
                          <label>POP Relacionado</label>
                          <input
                            name="related_pop"
                            value={item.pop?.title || 'Nenhum'}
                            readOnly
                          />
                        </div>

                        {processType.model_type !== 2 ? (
                          <div className="checkbox">
                            <label>Obrigatório</label>
                            <input
                              name="required"
                              checked={item.required}
                              type="checkbox"
                              disabled
                            />
                          </div>
                        ) : (
                          <div className="checkbox">
                            <label>Documento obrigatório</label>
                            <input
                              name="required"
                              checked={item.document_required === true}
                              type="checkbox"
                              disabled
                            />
                          </div>
                        )}
                      </section>
                    ))}

                    {processType.model_type !== 2 && (
                      <>
                        {processType.questions.length > 0 && (
                          <h4 className="description">PERGUNTAS</h4>
                        )}
                        {processType.questions.map((item, index) => (
                          <section key={index}>
                            <div className="document">
                              <label>Pergunta</label>
                              <input
                                name="question"
                                value={item.question || ''}
                                readOnly
                              />
                            </div>

                            <div className="document">
                              <label>Explicação</label>
                              <input
                                name="question"
                                value={item.explanation || ''}
                                readOnly
                              />
                            </div>

                            {processType.model_type === 0 &&
                              processType.process_type > 0 && (
                                <div className="type">
                                  <label>Tipo da pergunta</label>
                                  {item.quantitative_type === 0 && (
                                    <input
                                      name="quantitative_type"
                                      value="para empresa"
                                      readOnly
                                    />
                                  )}
                                  {item.quantitative_type === 1 && (
                                    <input
                                      name="quantitative_type"
                                      value="para cada sócio"
                                      readOnly
                                    />
                                  )}
                                  {item.quantitative_type === null && (
                                    <input
                                      name="quantitative_type"
                                      value=""
                                      readOnly
                                    />
                                  )}
                                </div>
                              )}

                            <div className="checkbox">
                              <label>Obrigatório</label>
                              <input
                                name="required"
                                checked={item.required}
                                type="checkbox"
                                disabled
                              />
                            </div>

                            {TypesQuestion.find(
                              option => option.value === item.type_question
                            ) && (
                              <div>
                                {
                                  TypesQuestion.find(
                                    option =>
                                      option.value === item.type_question
                                  ).label
                                }
                              </div>
                            )}

                            <div className="type">
                              <label>Tipo da pergunta</label>
                              <input
                                name="type_question"
                                value={
                                  TypesQuestion.find(
                                    option =>
                                      option.value === item.type_question
                                  )?.label || ''
                                }
                                readOnly
                              />
                            </div>
                          </section>
                        ))}
                      </>
                    )}

                    {processType.model_type !== 2 && (
                      <>
                        {processType.phases.length > 0 && (
                          <h4 className="description">ETAPAS</h4>
                        )}
                        {processType.phases.map((item, index) => (
                          <div key={index} className="phase">
                            <section>
                              <div className="abreviate">
                                <label>Abreviatura</label>
                                <input
                                  name="abbreviation"
                                  value={item.abbreviation || ''}
                                  readOnly
                                />
                              </div>
                              <div className="document">
                                <label>Documentos</label>
                                <input
                                  name="document"
                                  value={item.document || ''}
                                  readOnly
                                />
                              </div>
                              <div className="type">
                                <label>Responsável</label>
                                <input
                                  name="responsible_id"
                                  value={
                                    item.responsible_id
                                      ? usersOptions.find(
                                          element =>
                                            element.value ===
                                            item.responsible_id
                                        ).label
                                      : 'Nenhum'
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="type">
                                <label>Tipo</label>
                                {(item.type === 0 || !item.type) && (
                                  <input name="type" value="Padrão" readOnly />
                                )}
                                {item.type === 1 && (
                                  <input
                                    name="type"
                                    value="Requer pagamento"
                                    readOnly
                                  />
                                )}
                                {item.type === 2 && (
                                  <input
                                    name="type"
                                    value="Ativar novo processo"
                                    readOnly
                                  />
                                )}
                              </div>

                              {item.type === 2 && (
                                <div className="type">
                                  <label>Modelo do processo</label>
                                  <input
                                    name="model_to_create"
                                    value={
                                      processTypes.find(
                                        model =>
                                          model.id === item.model_to_create
                                      )?.title || ''
                                    }
                                    readOnly
                                  />
                                </div>
                              )}
                            </section>
                            <section>
                              <div className="deadline">
                                <label>Prazo Interno</label>
                                <input
                                  name="dead_line"
                                  value={item.dead_line}
                                  readOnly
                                />
                              </div>
                              <div className="deadline">
                                <label>Prazo P/ Cliente</label>
                                <input
                                  name="dead_line_client"
                                  value={item.dead_line_client || 0}
                                  readOnly
                                />
                              </div>

                              <div className="checkbox">
                                <label>Notificar</label>
                                <input
                                  name="notificate"
                                  checked={item.notificate}
                                  type="checkbox"
                                  disabled
                                />
                              </div>

                              <div className="checkbox">
                                <label>Obrigatório</label>
                                <input
                                  name="required"
                                  checked={item.required}
                                  type="checkbox"
                                  disabled
                                />
                              </div>

                              <div className="type">
                                <label>POP Relacionado</label>
                                <input
                                  name="related_pop"
                                  value={item.pop?.title || 'Nenhum'}
                                  readOnly
                                />
                              </div>

                              <div className="checkbox">
                                <label>Documento Obrigatório</label>
                                <input
                                  name="required"
                                  checked={item.document_required || false}
                                  type="checkbox"
                                  disabled
                                />
                              </div>

                              {item.document_required && (
                                <>
                                  <div className="checkbox">
                                    <label>Protocolo automático</label>
                                    <input
                                      name="required"
                                      checked={item.generate_protocol || false}
                                      type="checkbox"
                                      disabled
                                    />
                                  </div>
                                  <div className="type">
                                    <label>Documento</label>
                                    <input
                                      name="document_id"
                                      value={item.doc?.description || ''}
                                      readOnly
                                    />
                                  </div>
                                </>
                              )}
                            </section>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </DetailsContainer>
            )}
          </Content>
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default List;

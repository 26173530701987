import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaCog,
  FaEraser,
  FaPlus,
  FaSearch,
  FaTimes,
  FaWhatsapp,
} from 'react-icons/fa';
import { format, parseISO, subDays } from 'date-fns';

import { Form } from '@unform/web';
import { DatePicker, Input, Select } from '~/components/Form';
import Pagination from '~/components/Pagination';
import { SimpleTable, TableLoading } from '~/components/Table';

import { useAuth } from '~/hooks';
import api from '~/services/api';
import history from '~/services/history';
import formatContact from '~/util/formatContact';

import { Container, Controls, Header, Filter, DateContainer } from './styles';
import PermissionComponent from '~/components/PermissionComponent';
import PopUp from '~/components/Popup';

const List = () => {
  const { company } = useAuth();

  const filterRef = useRef(null);

  const [messagesLoading, setMessagesLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [clientsLoading, setClientsLoading] = useState(true);
  const [clientsOptions, setClientsOptions] = useState([]);

  const [startPeriodForDatePicker, setStartPeriodForDatePicker] = useState(
    null
  );
  const [endPeriodForDatePicker, setEndPeriodForDatePicker] = useState(null);

  const [sortConfig, setSortConfig] = useState({});

  const [selectedClient, setSelectedClient] = useState(() => {
    const clientStorage = localStorage.getItem(
      '@Diretiva:whatsapp:filter:client'
    );

    if (clientStorage) {
      return JSON.parse(clientStorage);
    }

    return {
      value: null,
      label: 'Todos',
    };
  });

  const [startPeriod, setStartPeriod] = useState(() => {
    const startPeriodStorage = localStorage.getItem(
      '@Diretiva:whatsapp:filter:start'
    );

    if (startPeriodStorage && startPeriodStorage !== 'null') {
      setStartPeriodForDatePicker(new Date(startPeriodStorage));

      return new Date(startPeriodStorage);
    }

    setStartPeriodForDatePicker(subDays(new Date(), 7));
    return subDays(new Date(), 7);
  });

  const [endPeriod, setEndPeriod] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:whatsapp:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      setEndPeriodForDatePicker(new Date(endPeriodStorage));

      return new Date(endPeriodStorage);
    }

    setEndPeriodForDatePicker(new Date());
    return new Date();
  });

  useEffect(() => {
    async function loadClients() {
      if (company) {
        try {
          setClientsLoading(true);
          const response = await api.get(`/relationships`, {
            params: {
              company_id: company.id,
              selectOnly: true,
              type: 1,
            },
          });

          const { data } = response;

          if (data.length > 0) {
            data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            data.unshift({
              id: null,
              name: 'Todos',
            });

            setClientsOptions(
              data.map(item => ({
                value: item.id,
                label: item.name,
              }))
            );
          } else {
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            history.push('/');
          }
          setClientsLoading(false);
        } catch {
          toast.error('Falha ao buscar clientes.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push('/');
        }
      }
    }

    loadClients();
  }, [company]);

  useEffect(() => {
    async function loadMessages() {
      if (company) {
        try {
          setMessagesLoading(true);

          const response = await api.get('/whatsapp-messages', {
            params: {
              page: currentPage,
              company_id: company.id,
              start_period: startPeriod,
              end_period: endPeriod,
              client_id: selectedClient.value,
              key: sortConfig.key,
              direction: sortConfig.direction,
            },
          });

          const { docs, pages, total } = response.data;

          setTotalMessages(total);
          setTotalPages(pages);

          if (docs.length > 0) {
            const dataFormatted = docs.map(message => ({
              ...message,
              date: format(parseISO(message.created_at), 'dd/MM/yyyy'),
              hour: format(parseISO(message.created_at), 'HH:mm'),
              phone: message.client.phone
                ? message.client.phone < 9999999999
                  ? formatContact(2, message.client.phone)
                  : formatContact(3, message.client.phone)
                : '',
              send_message: message.send_message
                ? format(parseISO(message.send_message), 'dd/MM/yyyy')
                : 'Não foi enviado',
              send_hour: message.send_message
                ? format(parseISO(message.send_message), 'HH:mm')
                : '',
            }));

            setMessages(dataFormatted);
          } else {
            setMessages([]);
            toast.warn('Nenhuma mensagem encontrada.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setMessagesLoading(false);
        } catch {
          setMessagesLoading(false);
          toast.error('Falha ao buscar mensagens.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    loadMessages();
  }, [
    company,
    endPeriod,
    startPeriod,
    selectedClient,
    currentPage,
    sortConfig,
  ]);

  const handleFilter = useCallback(
    data => {
      setCurrentPage(1);

      setSelectedClient(
        clientsOptions.find(option => option.value === data.client)
      );
      localStorage.setItem(
        '@Diretiva:whatsapp:filter:client',
        JSON.stringify(
          clientsOptions.find(option => option.value === data.client)
        )
      );

      setStartPeriod(data.start);
      localStorage.setItem('@Diretiva:whatsapp:filter:start', data.start);

      setEndPeriod(data.end);
      localStorage.setItem('@Diretiva:whatsapp:filter:end', data.end);
    },
    [clientsOptions]
  );

  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    filterRef.current.setFieldValue('client', {
      value: null,
      label: 'Todos',
    });
    setSelectedClient({
      value: null,
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:whatsapp:filter:client');

    setStartPeriod(subDays(new Date(), 7));
    setStartPeriodForDatePicker(subDays(new Date(), 7));
    localStorage.removeItem('@Diretiva:whatsapp:filter:start');

    setEndPeriod(new Date());
    setEndPeriodForDatePicker(new Date());
    localStorage.removeItem('@Diretiva:whatsapp:filter:end');

    setSortConfig({});
  }, []);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const [visiblePopUp, setVisiblePopUp] = useState(false);
  const [companyData, setCompanyData] = useState({
    token: '',
    session: '',
  });

  const [test, setTest] = useState([
    {
      id: '',
      className: 'situation',
      title: 'Situação',
    },
    {
      id: 'created_at',
      className: 'date',
      title: 'Criação',
      sort: 'na',
    },
    {
      id: '',
      className: 'hour',
      title: '',
    },
    {
      id: 'send_message',
      className: 'send_message',
      title: 'Envio',
      sort: 'na',
    },
    {
      id: '',
      className: 'send_hour',
      title: '',
    },
    {
      id: '',
      className: 'sender',
      title: 'Sender',
    },
    {
      id: '',
      className: 'client',
      title: 'Cliente',
    },
    {
      id: '',
      className: 'resiver',
      title: 'Destinatário',
    },
    {
      id: '',
      className: 'phone',
      title: 'Telefone',
    },
    {
      id: '',
      className: 'message',
      title: 'Mensagem',
    },
  ]);

  function click(index) {
    setTest(currentTest => {
      const newTest = [...currentTest];

      if (newTest[index].sort == 'asc') {
        newTest[index].sort = 'na';
      } else if (newTest[index].sort == 'desc') {
        newTest[index].sort = 'asc';
      } else if (newTest[index].sort == 'na') {
        newTest[index].sort = 'desc';
      }

      const element = document.getElementById(test[index].id);
      element.classList.add(newTest[index].sort);
      setSortConfig({ key: test[index].id, direction: newTest[index].sort });
      return newTest;
    });
  }

  const formRef = useRef(null);

  const handleSubmit = useCallback(async data => {
    if (company) {
      try {
        await api.put(`/companies/whatsapp/${company.id}`, {
          company_id: company.id,
          token_whatsapp: data.token,
          session_whatsapp: data.session,
        });
        toast.success('Token e sessão foram atualizados.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch {
        toast.error('Falha ao atualizar.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      setVisiblePopUp(false);
    }
  }, []);

  const SetTokenAndSession = async () => {
    if (company) {
      if (companyData.token == '' && companyData.session == '') {
        const response = await api.get(`/companies/whatsapp/${company.id}`);
        const { data } = response;
        setCompanyData({
          token: data.token_whatsapp ? data.token_whatsapp : '',
          session: data.session_whatsapp ? data.session_whatsapp : '',
        });
      }
      setVisiblePopUp(true);
    }
  };

  const popUpBody = (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <section>
        <Input
          name="token"
          className="token"
          label="Token"
          defaultValue={companyData.token}
        />
      </section>
      <section>
        <Input
          name="session"
          className="session"
          label="Sessão"
          defaultValue={companyData.session}
        />
      </section>
      <button type="submit">Salvar</button>
    </Form>
  );

  return (
    <>
      <PopUp
        visible={visiblePopUp}
        setVisible={setVisiblePopUp}
        body={popUpBody}
        title="Configurações de whatsapp"
        icon={<FaCog size="18" color="#01579b" />}
      />
      <Container>
        <Header>
          <div>
            <FaWhatsapp size={20} color="#44546a" />
            <h1>Whatsapp</h1>
          </div>
          <aside>
            <PermissionComponent level={9}>
              <button type="button" onClick={() => SetTokenAndSession()}>
                <FaCog size={20} color="#44546a" />
              </button>
            </PermissionComponent>
            <Link to="/">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        <Controls>
          <Link to="/whatsapp/new">
            <FaPlus />
            <span>Novo</span>
          </Link>
          <button type="button" onClick={resetFilter}>
            <FaEraser />
            <span>Limpar filtros</span>
          </button>
        </Controls>

        <Filter
          ref={filterRef}
          onSubmit={handleFilter}
          loading={clientsLoading ? 1 : 0}
        >
          <Select
            label="Cliente"
            name="client"
            className="client"
            options={clientsOptions}
            defaultValue={selectedClient}
          />

          <DateContainer className="period">
            <label>Período</label>
            <div>
              <DatePicker
                name="start"
                selected={startPeriodForDatePicker}
                onChange={date => setStartPeriodForDatePicker(date)}
              />
              <span>até</span>
              <DatePicker
                name="end"
                selected={endPeriodForDatePicker}
                onChange={date => setEndPeriodForDatePicker(date)}
              />
            </div>
          </DateContainer>

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>

        {messagesLoading ? (
          <TableLoading />
        ) : (
          <>
            <SimpleTable tbody={messages} thead={test} click={click} />
          </>
        )}
        <Pagination
          loading={clientsLoading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalMessages}
          handlePage={handlePage}
        />
      </Container>
    </>
  );
};

export default List;
